import { Typography } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useState } from "react";
import { useAuth } from "src/context";
import {
  BloodTotalDTO,
  getCurrentUser,
  http,
  ListBloodType,
  PRIMARY_COLOR,
} from "src/utils";
import { useNavigate } from "react-router-dom";
import { allColumns } from "./allColumns";
import Button from "src/components/Button";
import RequestBloodForm from "../RequestBloodForm";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { ToastError } from "src/utils/toastOptions";

type QuantityTake = {
  numberbloodid: number;
  quantity: number;
};

const ListBlood: React.FC = () => {
  const { user } = useAuth();
  const [bloodTypeId, setBloodTypeId] = useState(0);
  const currentUser = user?.userId ? user : getCurrentUser();
  const navigate = useNavigate();
  const [listBlood, setListBlood] = useState<ListBloodType[]>([]);
  const [open, setOpen] = useState(false);
  const [totalBloodOfType, setTotalBloodOfType] = useState<BloodTotalDTO>();
  const [quantityTake, setQuantityTake] = React.useState<QuantityTake[]>([]);
  console.log(currentUser, "ds");

  const fetchBloodData = () => {
    http
      .get(`Hopital/displaysremainingblood?id=${currentUser?.userId}`)
      .then((res) => {
        setListBlood(res?.data?.data);
      });
  };

  useEffect(() => {
    fetchBloodData();
  }, [currentUser?.userId]);

  const onRequestBlood = async () => {
    const body = {
      hospitalid: currentUser?.userId,
      datetake: dayjs(),
      bloodtypeid: bloodTypeId,
      quantityTake: quantityTake,
    };

    try {
      const res = await http.post(`Hopital/addtakeblood`, body);
      setListBlood(res?.data?.data);
      setOpen(false);
      toast.success("Blood request submitted successfully!");
      fetchBloodData();
    } catch (error) {
      console.error("Failed to request blood", error);
      toast.error("Failed to submit blood request. Please try again.");
    }
  };

  const handleAddBlood = () => {
    navigate("/manage/add-blood");
  };

  const handleRequestBlood = (bloodtypeid: number, totalBloodDTOs: any) => {
    if (currentUser.role === 3) {
      ToastError("Tài khoản bạn không có quyền!");
    } else {
      if (bloodtypeid) setBloodTypeId(bloodtypeid);
      setTotalBloodOfType(totalBloodDTOs);
      setOpen(true);
    }
  };
  const table = useMaterialReactTable({
    columns: allColumns(handleRequestBlood),
    data: listBlood,
    enableRowPinning: false,
    enableSorting: false,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    paginationDisplayMode: "pages",
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "whitesmoke",
      },
    },
  });

  return (
    <div className="ml-4">
      <div className="w-full flex flew-row justify-between items-center">
        <Typography variant="h4" color={PRIMARY_COLOR} mb={2}>
          Danh sách máu đang có trong kho
        </Typography>
        {currentUser.role === 2 ? (
          <>
            <Button onClick={handleAddBlood}>Tạo</Button>
          </>
        ) : null}
      </div>
      <MaterialReactTable table={table} />
      <RequestBloodForm
        data={totalBloodOfType}
        setOpen={setOpen}
        open={open}
        setQuantityTake={setQuantityTake}
        quantityTake={quantityTake}
        onRequestBlood={onRequestBlood}
      />
    </div>
  );
};

export default ListBlood;
