import * as yup from "yup";
import dayjs from "dayjs";

const isAdult = (date: string) => {
  return dayjs().diff(dayjs(date), "year") >= 18;
};
export const schema = yup
  .object({
    email: yup
      .string()
      .email("Vui lòng nhập địa chỉ email hợp lệ")
      .required("Vui lòng nhập địa chỉ email của bạn"),
    cccd: yup
      .string()
      .matches(/^\d{9,12}$/, "CCCD phải có từ 9 đến 12 chữ số")
      .required("Vui lòng nhập số CCCD của bạn"),
    phoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, "Số điện thoại chỉ được chứa các chữ số")
      .min(10, "Số điện thoại phải có ít nhất 10 chữ số")
      .max(11, "Số điện thoại tối đa có thể có 11 chữ số")
      .required("Vui lòng nhập số điện thoại của bạn"),
    birthdate: yup
      .string()
      .required("Vui lòng nhập ngày sinh của bạn")
      .test("is-adult", "Bạn phải ít nhất 18 tuổi", (date) => isAdult(date)),
    gender: yup
      .number()
      .oneOf([0, 1], "Giới tính phải là 0 hoặc 1")
      .required("Vui lòng chọn giới tính"),
    fullname: yup
      .string()
      .matches(
        /^[a-zA-ZÀ-Ỹà-ỹ ]*$/, // Sử dụng ký tự unicode cho các ký tự tiếng Việt
        "Tên chỉ được chứa các ký tự chữ và khoảng trắng"
      )
      .required("Vui lòng nhập tên của bạn"),
    password: yup
      .string()
      .min(8, "Mật khẩu của bạn phải chứa ít nhất 8 ký tự")
      .required("Vui lòng nhập mật khẩu của bạn"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Mật khẩu phải trùng khớp"),
  })
  .required();
